import { useLayoutEffect } from 'react'
import create from 'zustand'
import createContext from 'zustand/context'
import shallow from 'zustand/shallow'
import { merge } from 'lodash'
let store

const initialState = {
  tasks: {},
}

const zustandContext = createContext()
export const Provider = zustandContext.Provider
// An example of how to get types
/** @type {import('zustand/index').UseStore<typeof initialState>} */
export const useStore = zustandContext.useStore

export const initializeStore = (preloadedState = {}) => {
  return create((set, get) => ({
    ...initialState,
    ...preloadedState,
    updateTask: (task) => {
      set((state) => {
        return {
          ...state,
          tasks: {
            ...state.tasks,
            [String(task.Id)]: {
              ...state.tasks[String(task.Id)],
              ...task,
            },
          },
        }
      })
    },
    updateTasks: (tasks) => {
      set((state) => {
        const mergedTasks = tasks.reduce((acc, task) => {
          acc[String(task.Id)] = merge(state.tasks[String(task.Id)] || {}, task)
          return acc
        }, {})
        return {
          ...state,
          tasks: {
            ...state.tasks,
            ...mergedTasks,
          },
        }
      })
    },
  }))
}

export function useCreateStore(initialState) {
  // For SSR & SSG, always use a new store.
  if (typeof window === 'undefined') {
    return () => initializeStore(initialState)
  }

  // For CSR, always re-use same store.
  store = store ?? initializeStore(initialState)
  // And if initialState changes, then merge states in the next render cycle.
  //
  // eslint complaining "React Hooks must be called in the exact same order in every component render"
  // is ignorable as this code runs in same order in a given environment
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    if (initialState && store) {
      store.setState({
        ...store.getState(),
        ...initialState,
      })
    }
  }, [initialState])

  return () => store
}

export function useTasksByIds(ids) {
  const { tasks } = useStore()
  return ids.map((id) => tasks[String(id)]).filter(Boolean)
}

export function useTaskById(id) {
  return useStore((store) => store.tasks[id])
}
