import { SessionProvider } from 'next-auth/react'
import QueryParamProvider from 'utils/QueryParamProvider'
import { useCreateStore, Provider } from 'store'
import 'react-datepicker/dist/react-datepicker.css'
import { ThemeProvider, defaultTheme } from 'evergreen-ui'

const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    Spinner: {
      ...defaultTheme.components.Spinner,
      appearances: {
        primary: {
          color: 'white',
        },
      },
    },
    Button: {
      ...defaultTheme.components.Button,
      appearances: {
        ...defaultTheme.components.Button.appearances,
        primary: {
          color: 'white',
          paddingX: 12,
          paddingY: 8,
          borderRadius: 5,
          stroke: 'white',
          backgroundColor: '#641a5a',
          _hover: {
            backgroundColor: '#663399',
          },
          _active: {
            backgroundColor: '#663399',
          },
          _focus: {
            boxShadow: '0 0 0 2px #641a5a',
          },
        },
      },
    },
  },
}

function App({ Component, pageProps, err }) {
  const createStore = useCreateStore(pageProps.initialZustandState)

  return (
    <ThemeProvider value={theme}>
      <QueryParamProvider>
        <SessionProvider session={pageProps.session}>
          <Provider createStore={createStore}>
            <Component {...pageProps} err={err} />
          </Provider>
        </SessionProvider>
      </QueryParamProvider>
    </ThemeProvider>
  )
}

export default App
